import React, { useState } from 'react'
import Layout from '../components/Layout.js'
import { Form, List, Gutter, Loader } from '@patomation/react-ui-components'
import { navigate } from 'gatsby'
import useMutation from '../hooks/useMutation'


const ADD_CONTACT = `mutation	{
  addContact(input: {
    name: "$name"
    email: "$email"
    message: "$message"
  }){
    name
    email
    message
  }
}
`

const Contact = () => {

  const [ complete, setComplete ] = useState(false)
  const [ submit, {loading} ] = useMutation(
    ADD_CONTACT, {
      onCompleted: (result) => {
        setComplete(true)
      }
    }
  )

  if(loading) return (
    <Layout>
      <Loader
        message='Submitting Please Wait'
        progressBar={true}
        progressBarColor='#86AC41'
        progressBarTime={10000}
        complete={complete}
        spinner={false}
        onComplete={() => {
          navigate('/contact-success')
        }}/>
    </Layout>
  )

  return (
   <Layout>
    <section>


    <List
      className='contactlist'
      style={{
        fontSize: '1rem'
      }}>
        <a href="tel:+66 810211784">
          <i className="fas fa-phone-square"/><Gutter vertical />
          +66 810211784
        </a>
        <a href="tel:+66 811336814">
          <i className="fas fa-phone-square"/><Gutter vertical />
          +66 811336814
        </a>
        <a href="mailto:info@privateguidechiangmai.com">
          <i className="fas fa-envelope-square"/><Gutter vertical />
          info@privateguidechiangmai.com
        </a>
    </List>

    <Gutter />

    <Form
      className='contactform'
      labels={true}
      onSubmit={(values) => {
        submit({variables: {
          name: values.name,
          email: values.email,
          message: values.message
         } })
      }}
      fields={[
        {
          name: 'name',
          type: 'text',
          label: 'Name',
          placeholder: 'Name',
          required: true
        },
        {
          name: 'email',
          type: 'email',
          label: 'Email',
          placeholder: 'Email address',
          required: true,
          validation: 'email'
        },
        {
          name: 'message',
          type: 'textarea',
          label: 'Message',
          placeholder: 'Enter message',
          required: true,
          validation: 'length>5' //TODO make this work
        },
      ]}
      buttonStyle={{
        background:'#86AC41', //green
        color:'#324851', //dark blue green
        width: 'auto'
      }}
      inputStyle={{
        textAlign: 'left'
      }}/>


    </section>
   </Layout>
 )

}

export default Contact
